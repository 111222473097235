<template>
  <g>
    <path v-if="icon === 'menu'" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
    <path v-if="icon === 'chevron-left'" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"/>
    <path v-if="icon === 'chevron-right'" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>

    <path v-if="icon === 'phone'" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />

    <path v-if="icon === 'share'" d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z" />
    <path v-if="icon === 'cast'" d="M1,10V12A9,9 0 0,1 10,21H12C12,14.92 7.07,10 1,10M1,14V16A5,5 0 0,1 6,21H8A7,7 0 0,0 1,14M1,18V21H4A3,3 0 0,0 1,18M21,3H3C1.89,3 1,3.89 1,5V8H3V5H21V19H14V21H21A2,2 0 0,0 23,19V5C23,3.89 22.1,3 21,3Z"/>
    <path v-if="icon === 'cast-connected'" d="M21,3H3C1.89,3 1,3.89 1,5V8H3V5H21V19H14V21H21A2,2 0 0,0 23,19V5C23,3.89 22.1,3 21,3M1,10V12A9,9 0 0,1 10,21H12C12,14.92 7.07,10 1,10M19,7H5V8.63C8.96,9.91 12.09,13.04 13.37,17H19M1,14V16A5,5 0 0,1 6,21H8A7,7 0 0,0 1,14M1,18V21H4A3,3 0 0,0 1,18Z"/>

    <path v-else-if="icon === 'rotate-screen'" d="M7.5,21.5C4.25,19.94 1.91,16.76 1.55,13H0.05C0.56,19.16 5.71,24 12,24L12.66,23.97L8.85,20.16M14.83,21.19L2.81,9.17L9.17,2.81L21.19,14.83M10.23,1.75C9.64,1.16 8.69,1.16 8.11,1.75L1.75,8.11C1.16,8.7 1.16,9.65 1.75,10.23L13.77,22.25C14.36,22.84 15.31,22.84 15.89,22.25L22.25,15.89C22.84,15.3 22.84,14.35 22.25,13.77L10.23,1.75M16.5,2.5C19.75,4.07 22.09,7.24 22.45,11H23.95C23.44,4.84 18.29,0 12,0L11.34,0.03L15.15,3.84L16.5,2.5Z" />

    <path v-else-if="icon === 'twitter'" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"/>
    <path v-else-if="icon === 'facebook'" d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"/>
    <path v-else-if="icon === 'instagram'" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"/>
    <path v-else-if="icon === 'pinterest'" d="M9.04,21.54C10,21.83 10.97,22 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12C2,16.25 4.67,19.9 8.44,21.34C8.35,20.56 8.26,19.27 8.44,18.38L9.59,13.44C9.59,13.44 9.3,12.86 9.3,11.94C9.3,10.56 10.16,9.53 11.14,9.53C12,9.53 12.4,10.16 12.4,10.97C12.4,11.83 11.83,13.06 11.54,14.24C11.37,15.22 12.06,16.08 13.06,16.08C14.84,16.08 16.22,14.18 16.22,11.5C16.22,9.1 14.5,7.46 12.03,7.46C9.21,7.46 7.55,9.56 7.55,11.77C7.55,12.63 7.83,13.5 8.29,14.07C8.38,14.13 8.38,14.21 8.35,14.36L8.06,15.45C8.06,15.62 7.95,15.68 7.78,15.56C6.5,15 5.76,13.18 5.76,11.71C5.76,8.55 8,5.68 12.32,5.68C15.76,5.68 18.44,8.15 18.44,11.43C18.44,14.87 16.31,17.63 13.26,17.63C12.29,17.63 11.34,17.11 11,16.5L10.33,18.87C10.1,19.73 9.47,20.88 9.04,21.57V21.54Z"/>
    <path v-else-if="icon === 'whatsapp'" d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
    <path v-else-if="icon === 'email'" d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />

    <path v-else-if="icon === 'cached'" d="M19,8L15,12H18A6,6 0 0,1 12,18C11,18 10.03,17.75 9.2,17.3L7.74,18.76C8.97,19.54 10.43,20 12,20A8,8 0 0,0 20,12H23M6,12A6,6 0 0,1 12,6C13,6 13.97,6.25 14.8,6.7L16.26,5.24C15.03,4.46 13.57,4 12,4A8,8 0 0,0 4,12H1L5,16L9,12" />
    <!-- viewBox="0 0 141.685 56.181" -->
    <path v-else-if="icon === 'scroller'" fill-rule="nonzero" d="M133.5 0c36.67 0 70.05 15.03 94.25 39.21l.06.07C251.99 63.47 267 96.84 267 133.5v151.22c0 36.67-15.03 70.04-39.21 94.24l-.06.06c-24.2 24.18-57.57 39.19-94.23 39.19-36.67 0-70.05-15.02-94.25-39.21l-.06-.06C15.01 354.74 0 321.37 0 284.72V133.5c0-36.67 15.03-70.04 39.21-94.25l.06-.06C63.47 15.02 96.84 0 133.5 0zM98.79 447.32l34.7 34.72 34.77-34.72 15.16 15.24-49.98 49.9-49.89-49.9 15.24-15.24zm34.71-130.89c12.5 0 22.74-10.23 22.74-22.73v-30.34c0-12.51-10.24-22.74-22.74-22.74-12.5 0-22.73 10.23-22.73 22.74v30.34c0 12.5 10.23 22.73 22.73 22.73zm0-294.87c-30.81 0-58.8 12.59-79.05 32.85-20.31 20.3-32.89 48.29-32.89 79.09v151.22c0 30.8 12.58 58.79 32.85 79.05 20.29 20.3 48.28 32.88 79.09 32.88 30.81 0 58.8-12.58 79.05-32.85 20.3-20.29 32.89-48.28 32.89-79.08V133.5c0-30.8-12.59-58.79-32.85-79.05-20.29-20.3-48.28-32.89-79.09-32.89z"/>

    <g v-else-if="icon === 'logo'">
      <path id="box" d="M140.185,42.954H1.5V12.958h138.685V42.954z M141.685,11.458h-1.5H1.5H0v1.5v29.997v1.5h1.5h138.685h1.5v-1.5
        V12.958V11.458z"/>
      <path id="collection" d="M31.212,50.424c0.807,0,1.391,0.271,1.935,0.743c0.072,0.064,0.072,0.16,0.008,0.225l-0.352,0.359
        c-0.056,0.072-0.128,0.072-0.2,0c-0.376-0.328-0.888-0.536-1.383-0.536c-1.144,0-2.007,0.96-2.007,2.079s0.872,2.071,2.015,2.071
        c0.584,0,0.992-0.232,1.375-0.528c0.072-0.056,0.144-0.048,0.192-0.008l0.368,0.36c0.064,0.056,0.048,0.159-0.008,0.216
        c-0.544,0.527-1.231,0.775-1.943,0.775c-1.599,0-2.887-1.271-2.887-2.87C28.326,51.711,29.613,50.424,31.212,50.424z
        M40.764,50.424c1.599,0,2.878,1.287,2.878,2.887c0,1.599-1.279,2.87-2.878,2.87s-2.871-1.271-2.871-2.87
        C37.894,51.711,39.165,50.424,40.764,50.424z M40.764,55.381c1.143,0,2.079-0.928,2.079-2.07c0-1.136-0.936-2.087-2.079-2.087
        c-1.135,0-2.071,0.951-2.071,2.087C38.693,54.453,39.629,55.381,40.764,55.381z M48.789,50.655c0-0.08,0.064-0.151,0.152-0.151
        h0.527c0.08,0,0.152,0.071,0.152,0.151v4.702h2.167c0.088,0,0.152,0.071,0.152,0.151v0.439c0,0.08-0.064,0.152-0.152,0.152h-2.846
        c-0.088,0-0.152-0.072-0.152-0.152V50.655z M56.781,50.655c0-0.08,0.064-0.151,0.152-0.151h0.527c0.08,0,0.152,0.071,0.152,0.151
        v4.702h2.167c0.088,0,0.152,0.071,0.152,0.151v0.439c0,0.08-0.064,0.152-0.152,0.152h-2.846c-0.088,0-0.152-0.072-0.152-0.152
        V50.655z M64.773,50.655c0-0.08,0.064-0.151,0.152-0.151h3.198c0.088,0,0.152,0.071,0.152,0.151v0.448
        c0,0.08-0.064,0.151-0.152,0.151h-2.526v1.632h2.135c0.08,0,0.152,0.071,0.152,0.151v0.448c0,0.088-0.072,0.151-0.152,0.151h-2.135
        v1.72h2.526c0.088,0,0.152,0.071,0.152,0.151v0.439c0,0.08-0.064,0.152-0.152,0.152h-3.198c-0.088,0-0.152-0.072-0.152-0.152
        V50.655z M75.964,50.424c0.808,0,1.392,0.271,1.936,0.743c0.071,0.064,0.071,0.16,0.008,0.225l-0.352,0.359
        c-0.057,0.072-0.128,0.072-0.2,0c-0.376-0.328-0.888-0.536-1.384-0.536c-1.143,0-2.007,0.96-2.007,2.079s0.872,2.071,2.016,2.071
        c0.583,0,0.991-0.232,1.375-0.528c0.072-0.056,0.144-0.048,0.191-0.008l0.368,0.36c0.064,0.056,0.048,0.159-0.008,0.216
        c-0.544,0.527-1.231,0.775-1.943,0.775c-1.599,0-2.886-1.271-2.886-2.87C73.078,51.711,74.365,50.424,75.964,50.424z M83.82,51.255
        h-1.303c-0.088,0-0.152-0.071-0.152-0.151v-0.448c0-0.08,0.064-0.151,0.152-0.151h3.438c0.088,0,0.152,0.071,0.152,0.151v0.448
        c0,0.08-0.064,0.151-0.152,0.151h-1.303v4.693c0,0.08-0.072,0.152-0.152,0.152h-0.527c-0.08,0-0.152-0.072-0.152-0.152V51.255z
        M90.973,50.655c0-0.08,0.072-0.151,0.152-0.151h0.527c0.08,0,0.152,0.071,0.152,0.151v5.293c0,0.08-0.072,0.152-0.152,0.152
        h-0.527c-0.08,0-0.152-0.072-0.152-0.152V50.655z M99.819,50.424c1.599,0,2.878,1.287,2.878,2.887c0,1.599-1.279,2.87-2.878,2.87
        c-1.6,0-2.87-1.271-2.87-2.87C96.949,51.711,98.22,50.424,99.819,50.424z M99.819,55.381c1.144,0,2.079-0.928,2.079-2.07
        c0-1.136-0.936-2.087-2.079-2.087c-1.136,0-2.071,0.951-2.071,2.087C97.748,54.453,98.684,55.381,99.819,55.381z M107.845,50.567
        c0-0.08,0.072-0.144,0.152-0.144h0.199l3.471,4.014h0.008v-3.782c0-0.08,0.064-0.151,0.152-0.151h0.512
        c0.079,0,0.151,0.071,0.151,0.151v5.382c0,0.079-0.072,0.144-0.151,0.144h-0.208l-3.471-4.126h-0.008v3.894
        c0,0.08-0.063,0.152-0.151,0.152h-0.504c-0.08,0-0.152-0.072-0.152-0.152V50.567z"/>
      <path id="ashtree" d="M21.187,33.405l4.93-11.14c0.032-0.064,0.112-0.112,0.192-0.112h0.048c0.08,0,0.16,0.016,0.208,0.112
        l4.882,11.14c0.032,0.097,0.032,0.192-0.144,0.192h-0.416c-0.112,0-0.192-0.08-0.224-0.145l-1.296-2.945h-6.115l-1.28,2.945
        c-0.032,0.064-0.112,0.145-0.224,0.145h-0.448C21.187,33.598,21.139,33.533,21.187,33.405z M29.094,29.884l-2.737-6.451h-0.032
        l-2.785,6.451H29.094z M37.363,32.109l0.144-0.192c0.112-0.128,0.224-0.176,0.352-0.064c0.16,0.144,1.345,1.233,2.961,1.233
        c1.457,0,2.689-0.977,2.689-2.146c0-1.424-1.168-2.064-3.105-2.721c-1.856-0.657-3.089-1.313-3.089-3.153
        c0-1.201,0.96-2.833,3.457-2.833c1.585,0,2.753,0.816,2.753,0.816c0.064,0.048,0.176,0.16,0.064,0.336
        c-0.048,0.064-0.096,0.144-0.128,0.208c-0.096,0.128-0.192,0.176-0.353,0.08c-0.048-0.032-1.12-0.752-2.353-0.752
        c-1.969,0-2.705,1.216-2.705,2.129c0,1.36,1.04,1.953,2.657,2.481c2.193,0.752,3.585,1.521,3.585,3.377
        c0,1.6-1.648,2.849-3.489,2.849c-1.905,0-3.186-1.12-3.394-1.312C37.315,32.365,37.235,32.285,37.363,32.109z M51.523,22.601
        c0-0.112,0.096-0.208,0.208-0.208h0.336c0.128,0,0.208,0.096,0.208,0.208v4.914h7.187v-4.914c0-0.112,0.096-0.208,0.208-0.208
        h0.336c0.128,0,0.208,0.096,0.208,0.208V33.39c0,0.112-0.08,0.208-0.208,0.208H59.67c-0.112,0-0.208-0.096-0.208-0.208v-5.202
        h-7.187v5.202c0,0.112-0.08,0.208-0.208,0.208h-0.336c-0.112,0-0.208-0.096-0.208-0.208V22.601z M70.1,23.082h-3.057
        c-0.16,0-0.208-0.096-0.208-0.24v-0.24c0-0.128,0.096-0.208,0.208-0.208h6.883c0.128,0,0.208,0.096,0.208,0.208v0.272
        c0,0.112-0.08,0.208-0.208,0.208h-3.073V33.39c0,0.112-0.097,0.208-0.257,0.208h-0.288c-0.111,0-0.208-0.096-0.208-0.208V23.082z
        M80.755,22.601c0-0.112,0.08-0.208,0.208-0.208h3.97c1.953,0,3.569,1.473,3.569,3.425c0,1.617-1.12,2.913-2.641,3.346l2.337,4.146
        c0.08,0.128,0.048,0.288-0.112,0.288H87.59c-0.112,0-0.176-0.048-0.224-0.145l-2.321-4.193c-0.208,0.016-0.384,0.016-0.592,0.016
        h-2.945v4.114c0,0.112-0.097,0.208-0.256,0.208h-0.289c-0.111,0-0.208-0.096-0.208-0.208V22.601z M84.901,28.604
        c1.536,0,2.833-1.2,2.833-2.785c0-1.521-1.297-2.689-2.85-2.689h-3.361v5.474H84.901z M95.955,22.601
        c0-0.112,0.097-0.208,0.208-0.208h6.419c0.112,0,0.208,0.096,0.208,0.208v0.272c0,0.112-0.096,0.208-0.208,0.208h-5.858v4.417
        h5.074c0.112,0,0.208,0.096,0.208,0.208v0.272c0,0.112-0.096,0.208-0.208,0.208h-5.074v4.738h5.858
        c0.112,0,0.208,0.096,0.208,0.208v0.256c0,0.112-0.096,0.208-0.208,0.208h-6.419c-0.111,0-0.208-0.096-0.208-0.208V22.601z
        M110.322,22.601c0-0.112,0.097-0.208,0.208-0.208h6.419c0.112,0,0.208,0.096,0.208,0.208v0.272c0,0.112-0.096,0.208-0.208,0.208
        h-5.858v4.417h5.074c0.112,0,0.208,0.096,0.208,0.208v0.272c0,0.112-0.096,0.208-0.208,0.208h-5.074v4.738h5.858
        c0.112,0,0.208,0.096,0.208,0.208v0.256c0,0.112-0.096,0.208-0.208,0.208h-6.419c-0.111,0-0.208-0.096-0.208-0.208V22.601z"/>
      <path id="the" d="M60.773,0.751H59.47c-0.088,0-0.152-0.072-0.152-0.152V0.152C59.318,0.072,59.382,0,59.47,0h3.438
        c0.088,0,0.152,0.072,0.152,0.152V0.6c0,0.08-0.064,0.152-0.152,0.152h-1.304v4.693c0,0.08-0.072,0.152-0.152,0.152h-0.528
        c-0.08,0-0.152-0.072-0.152-0.152V0.751z M67.925,0.152c0-0.08,0.072-0.152,0.152-0.152h0.528c0.088,0,0.152,0.072,0.152,0.152
        v2.23h2.927v-2.23c0-0.08,0.063-0.152,0.151-0.152h0.528c0.08,0,0.151,0.072,0.151,0.152v5.293c0,0.08-0.071,0.152-0.151,0.152
        h-0.528c-0.088,0-0.151-0.072-0.151-0.152V3.134h-2.927v2.311c0,0.08-0.063,0.152-0.152,0.152h-0.528
        c-0.08,0-0.152-0.072-0.152-0.152V0.152z M78.069,0.152c0-0.08,0.064-0.152,0.152-0.152h3.198c0.088,0,0.151,0.072,0.151,0.152V0.6
        c0,0.08-0.063,0.152-0.151,0.152h-2.526v1.631h2.135c0.079,0,0.151,0.072,0.151,0.152v0.448c0,0.088-0.072,0.152-0.151,0.152
        h-2.135v1.719h2.526c0.088,0,0.151,0.072,0.151,0.152v0.44c0,0.08-0.063,0.152-0.151,0.152h-3.198
        c-0.088,0-0.152-0.072-0.152-0.152V0.152z"/>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    /**
     * icon is the name of the icon.
     *
     * @param {String}
     */
    icon: {
      type: String,
      default: 'menu'
    }
  }
}
</script>
