<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    role="presentation"
  >
    <IconBase :icon="iconName" />
  </svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase.vue'

export default {
  components: {
    IconBase
  },
  props: {
    /**
     * iconName is the name of the icon.
     *
     * @param {String}
     */
    iconName: {
      type: String,
      default: 'menu'
    },
    /**
     * width is the svg width setting.
     *
     * @param {Number, String}
     */
    width: {
      type: [Number, String],
      default: 24
    },
    /**
     * height is the svg height setting.
     *
     * @param {Number, String}
     */
    height: {
      type: [Number, String],
      default: 24
    },
    /**
     * viewbox is the svg viewbox.
     *
     * @param {String}
     */
    viewbox: {
      type: String,
      default: '0 0 24 24',
    }
  }
}
</script>

<style lang="scss">
@import '../../../scss/init';
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}

svg.icon {
  &--white {
    fill: color(seed-white);
  }

  &--grey {
    fill: color(seed-grey);
  }

  &--blue {
    fill: color(seed-blue);
  }

  &--black {
    fill: color(seed-black);
  }

  // &-animate-1s {
  //   @include animate(all, $header-animation-timing);
  // }
}
</style>
